<template>
  <apexchart
    ref="chartRef"
    height="500"
    :series="series"
    :options="chartOptions"
  ></apexchart>
</template>

<script>
import { ref, watch } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
export default {
  name: "RevenueChart",
  props: {
    series: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
    currencyValues: {
      type: Boolean,
      default: true,
    },
    deselectSeries: {
      type: Boolean,
      default: false,
    },
    strokeWidth: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const chartRef = ref(null);
    const chartOptions = {
      chart: {
        height: 500,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: props.colors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: props.strokeWidth.length
          ? props.strokeWidth
          : Array(props.categories.length).fill(5),
        curve: "smooth",
      },
      legend: {
        show: true,
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: props.categories,
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            if (props.currencyValues) {
              return "€ " + Number(value).toLocaleString();
            }
            return Number(value).toLocaleString();
          },
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: [
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    };
    watch(
      () => props.deselectSeries,
      (deselect) => {
        if (deselect) {
          props.series.forEach((currentSeries) => {
            chartRef.value.hideSeries(currentSeries.name);
          });
        }
        context.emit("deselected");
      }
    );

    return { chartOptions, chartRef };
  },
};
</script>

<style scoped></style>
