
import { computed, defineComponent } from "vue";
import KTMenu from "@/layout/header/Menu.vue";
import KTTopbar from "@/layout/header/Topbar.vue";
import SearchBar from "@/artists/components/SearchBar.vue";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import { getAuth } from "firebase/auth";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTMenu,
    KTTopbar,
    SearchBar,
  },
  setup() {
    const isUserAuthenticatedByFirebase = computed(() => {
      return !!getAuth().currentUser;
    });

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      isUserAuthenticatedByFirebase,
    };
  },
});
