export const platforms = {
  instagram: {
    name: 'instagram',
    color: "#C14B84",
    icon: '/media/svg/social-logos/instagram.svg',
    indicator: 'Followers'
  },
  twitter: {
    name: 'twitter',
    color: "#01ACEE",
    icon: '/media/svg/social-logos/twitter.svg',
    indicator: 'Followers'
  },
  facebook: {
    name: 'facebook',
    color: "#4767AA",
    icon: '/media/svg/social-logos/facebook.svg',
    indicator: 'Followers'
  },
  spotify: {
    name: 'spotify',
    color: "#48B955",
    icon: '/media/svg/social-logos/spotify.svg',
    indicator: "Listeners"
  },
  deezer: {
    name: 'deezer',
    color: "#DC5546",
    icon: '/media/svg/social-logos/deezer.svg',
    indicator: "Listeners"
  },
  soundcloud: {
    name: 'soundcloud',
    color: "#FB663B",
    icon: 'media/svg/social-logos/soundcloud.svg',
    indicator: 'Followers'
  },
  youtube: {
    name: 'youtube',
    color: "#FA423B",
    icon: 'media/svg/social-logos/youtube.svg',
    indicator: 'Views'
  },
  tiktok: {
    name: 'tiktok',
    color: "#000000",
    icon: 'media/svg/social-logos/tiktok.svg',
    indicator: 'Followers'
  },
  shazam: {
    name: 'shazam',
    color: "#2979FF",
    icon: 'media/svg/social-logos/shazam.svg',
    indicator: 'Listeners'
  }
}